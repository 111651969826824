// Logo component for NavBar

import { Link } from "react-router-dom";

function NavBarLogo() {
  return (
    <Link to="/">
      <svg
        id="ad135c08-350c-42e3-9120-ef28cc481eb1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 182.85 165"
        className="max-h-20 min-w-[12] navsvghover fill-white"
      >
        <defs></defs>
        <path
          className="e611bd60-f45c-43d6-aa2f-579d3f52d223"
          d="M94.34,44.47Q92.21,60.33,84.08,78.72a143.27,143.27,0,0,1-16.4,28.67q-9,11.94-14.5,12-6.76,0-19.27-8.41a1.93,1.93,0,0,1-1-1.57c0-1.46.84-2.19,2.53-2.19a3.18,3.18,0,0,1,1.37.34,23.35,23.35,0,0,0,11.34,3.56q8.55.41,21.88-25.09Q83.26,60.88,85.38,41.12q.69-6.36-6.83-9.44a7.08,7.08,0,0,0-3.08-.54A64.41,64.41,0,0,0,50,40.71Q43.55,56,37,76.94a365.94,365.94,0,0,0-10.39,39.51q-.68,3.42-2.87,3.42a4.86,4.86,0,0,1-1.91-.48,19.07,19.07,0,0,1-4.65-3,6,6,0,0,1-1.51-4.3A62.73,62.73,0,0,1,17.5,98.33q.48-2,1.44-2t4.71,3.15Q33,67.85,41.29,45.29,26.05,54.86,17.77,63.75a7.13,7.13,0,0,0-1.91,4.92,13,13,0,0,0,1.64,5.74,18,18,0,0,1,1.64,3.69c0,.78-.52,1.16-1.57,1.16a2.63,2.63,0,0,1-2.05-.75Q7,68.46,7,64.63q0-4,5.47-8.95Q24,45.23,45.94,32.57q3.62-8.47,5.26-8.47,1.23,0,5.06,3.69l7.52-3.42A13.68,13.68,0,0,1,68.91,23q3.27,0,8.81,3.69a1.25,1.25,0,0,1,.89-.27q3.84,0,11.69,7.11,4.24,3.9,4.24,8.41A19.19,19.19,0,0,1,94.34,44.47Z"
        />
        <path
          className="e611bd60-f45c-43d6-aa2f-579d3f52d223"
          d="M159.71,72.8c1.36.59,2,1.39,2,2.39,0,1.19-.92,1.91-2.74,2.19q-4.71.75-5.88,2.6-.54.88-2.12,7.93-5.88,26.52-13.26,45-2.38,6-6.7,6-3.75,0-14.56-6.9a1.91,1.91,0,0,1-1.09-1.58c0-.95.66-1.43,2-1.43a8.56,8.56,0,0,1,2.53.68,8.61,8.61,0,0,0,3,.69q3.48,0,6.42-4.38,7.05-10.53,15-39.92a129,129,0,0,1-18.86,17.77q-14,10.8-22.83,10.8A10.55,10.55,0,0,1,94.35,111q-5.46-3.29-7.58-6A13.22,13.22,0,0,1,84,95.77Q84.79,81.07,95,63.84a119.66,119.66,0,0,1,21.19-26.38Q128.13,26.45,135,26q3.48-.21,10.66,5.06c.37-2.46,1-3.69,2-3.69s2.52.57,4.85,1.71c.86.41,1.37.86,1.5,1.36.28.92.18,3-.27,6.29a62.37,62.37,0,0,1-4,15.45,3,3,0,0,1-3,2.05,29.48,29.48,0,0,1-5-.82c-1.6-.32-2.39-1-2.39-2,0-.77.45-1.16,1.36-1.16a2.35,2.35,0,0,1,.62.07,47.26,47.26,0,0,0,3.21-13.88c.18-1.92-.93-2.87-3.35-2.87A17.37,17.37,0,0,0,133.25,36q-12.91,7-25.4,24.54T93.12,93a25,25,0,0,0-.41,4.24,11.86,11.86,0,0,0,5.54,10.46,6.58,6.58,0,0,0,3.62,1q7.86,0,22.36-12A140.11,140.11,0,0,0,143,78.54q-14,1.09-15.59,1.09c-2.37,0-4-.38-5-1.16q-1.09-.89-2.25-4.17a5.19,5.19,0,0,1-.42-1.71c0-.54.25-.82.76-.82,0,0,1.5.48,4.37,1.44q1.84.62,11,.2,8.47-.41,11.55-.89c1-1.68,2.16-2.52,3.62-2.52S155.51,70.93,159.71,72.8Z"
        />
        <path
          className="e611bd60-f45c-43d6-aa2f-579d3f52d223"
          d="M121.38,116.86c.63-1.1,1.2-1.65,1.7-1.65s1.26.77,1.91,2.29a11,11,0,0,1,1,2.93c0,.82-1,1.68-3.11,2.58q-5.56,2.22-13.95,4.92c-.82.16-.58.86.71,2.11a95.94,95.94,0,0,1,11.19,12.6,7,7,0,0,1,1.41,2.57c0,.44-.63.79-1.88,1.06a11.06,11.06,0,0,1-2.17.29c-.78,0-1.42-.7-1.93-2.11q-1.17-3.27-11.31-13.88a10.37,10.37,0,0,0-2.87-1.94,3.47,3.47,0,0,1-1.79-2.17,10.53,10.53,0,0,1-.44-1.75c.08-.31.47-.53,1.17-.65a75.6,75.6,0,0,0,12-3.19Q119.51,118.62,121.38,116.86Z"
        />
        <path
          className="e611bd60-f45c-43d6-aa2f-579d3f52d223"
          d="M177.27,134.2a5.9,5.9,0,0,1,.38,1.72c0,.32-.41.53-1.11.65a75.62,75.62,0,0,0-11.95,3.16c-4.42,1.53-7.23,2.87-8.44,4.05-.63,1.09-1.19,1.64-1.7,1.64s-1.27-.77-1.93-2.29a10.67,10.67,0,0,1-1-2.87c0-.86,1.06-1.72,3.17-2.58q5.56-2.28,13.94-4.92c.82-.16.59-.88-.7-2.17A95.94,95.94,0,0,1,156.74,118a7.09,7.09,0,0,1-1.41-2.52c0-.46.62-.82,1.88-1a11.71,11.71,0,0,1,2.16-.29c.78,0,1.43.68,1.94,2.05q1.17,3.28,11.25,13.94a10.21,10.21,0,0,0,2.87,1.94A3.23,3.23,0,0,1,177.27,134.2Z"
        />
        <path
          className="e611bd60-f45c-43d6-aa2f-579d3f52d223"
          d="M157.41,100.93l-10.79,34q-2.07,6.51-1.44,8.67a1.48,1.48,0,0,0,.22.44,2.11,2.11,0,0,1,.22.47.93.93,0,0,1-.69,1.3,2.28,2.28,0,0,1-1.35-.13l-2-.75a1.66,1.66,0,0,1-1.06-1.13,3.5,3.5,0,0,1,.16-2l11-34.42c1.12-3.53,1.46-6,1-7.52a2.63,2.63,0,0,0-.29-.59,1.81,1.81,0,0,1-.28-.56c-.16-.54,0-.88.49-1a1,1,0,0,1,.49,0l3.14.35a1.55,1.55,0,0,1,1.13,1A4.16,4.16,0,0,1,157.41,100.93Z"
        />
      </svg>
    </Link>
  );
}

export default NavBarLogo;
